import React, { useEffect } from "react"
import styled from "styled-components"

import { Banner, Section, BrianSmithContact } from "../components/elements"
import {
  Col,
  Color,
  Media,
  Wrapper,
  AlignCenter,
  ButtonB,
} from "../components/utilities"
import { Layout } from "../components/layouts/Layout"

import brian from "../images/brian.jpg"
import { graphql } from "gatsby"

const SpecialGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;

  ${Media.below.tablet`
    text-align: center;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  `}

  img {
    margin-left: 4rem;
    border-radius: 5px;
    width: 75%;

    ${Media.below.tablet`
        margin-left: 0;
    `}
  }
`

const OrangeBar = styled.div`
  height: 3px;
  width: 40px;
  background-color: ${Color.orange};
  margin-bottom: 1rem;

  ${Media.below.tablet`
    display: none;
  `}
`
const LawyerBadge = styled.div`
  text-align: center;
  margin-top: 1rem;

  .superBadge {
    display: inline-block;
  }
`

const ContactContainer = styled.div`
  display: inline-block;
  text-align: left;
`

const BrianSmith = ({ data }) => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://www.superlawyers.com/static/sl-badge/v1/load.min.js"
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return (
    <Layout title="Brian D. Smith">
      <Banner title="Brian D. Smith" />
      <Section>
        <Wrapper>
          <SpecialGrid>
            <Col>
              <h3>Brian D. Smith</h3>
              <OrangeBar />
              <p>
                Brian D. Smith is of counsel with Pfost Law, Ltd. His principle
                areas of practice include family law (which includes divorce,
                dissolution, child custody, child support, and adoptions), civil
                litigation, and trust and estate administration.
                <br />
                <br />
                Mr. Smith attended the University of Toledo, College of Law, and
                was the Assistant Managing Editor of the Law Review and was a
                certified legal intern in the Bowling Green City Prosecutor’s
                Office. After graduation he was admitted to practice law in
                Ohio, and opened a solo practice in 2008. As a solo practitioner
                Mr. Smith represented individuals in many areas including
                criminal defense, estate planning and civil litigation, but his
                main practice area was family law. Since that time Mr. Smith has
                expanded his practice to include trust and estate
                administration.
                <br />
                <br />
                Mr. Smith was born and raised in Northwest Ohio, is married and
                has two children. Mr. Smith is a former President of the Wood
                County Bar Association, active member of the character and
                fitness and grievance committees for the Wood County Bar
                Association, active member of the Toledo Bar Association, Ohio State Bar Association, and an
                Eagle Scout.
                <br />
                <br />
                <h4>EDUCATION</h4>
                <ul>
                  <li>
                    University of Toledo, College of Law, Juris Doctor (cum
                    laude) 2008
                  </li>
                  <li>
                    Lourdes College, B.A. History and B.A. International
                    Business (magna cum laude) 2004
                  </li>
                </ul>
                <h4>BAR ADMISSIONS</h4>
                <ul>
                  <li>Ohio, 2008</li>
                  <li>U.S. District Court for the Northern District of Ohio</li>
                </ul>
                <br />
                <h4>PROFESSIONAL AFFILIATIONS</h4>
                <br />
                <ul>
                  <li>Ohio State Bar Association</li>
                  <li>
                    Wood County Bar Association (President, 2013)
                    <ul>
                      <li>Character and Fitness Committee</li>
                      <li>Grievance Committee (Chair 2017-2018)</li>
                    </ul>
                  </li>

                  <li>
                    Toledo Bar Association
                    <ul>
                      <li>Municipal Court Committee</li>
                      <li>Juvenile Law Committee</li>
                      <li>Domestic Relations Committee</li>
                    </ul>
                  </li>
                </ul>
              </p>
            </Col>
            <Col>
              <img src={brian} />
              <br />
              <br />
              <AlignCenter>
                <ContactContainer>
                  <BrianSmithContact />
                </ContactContainer>
              </AlignCenter>
              <br />
              <br />
              <AlignCenter>
                <ButtonB
                  href={data.file.publicURL}
                  target="_blank"
                  modifiers={["primaryOutline"]}
                >
                  Download vCard
                </ButtonB>
              </AlignCenter>
              <br />
              <br />
              <LawyerBadge>
                <div
                  data-slbadge="v1-rsbadge-blue"
                  className="superBadge"
                  style={{
                    width: "180px",
                    height: "150px",
                    borderRadius: "12px",
                    fontFamily: "arial, sans-serif",
                    color: "grey",
                    textAlign: "center",
                    display: "inline-block",
                  }}
                >
                  <a
                    className="slbadge_profileurl"
                    title="View the profile of Ohio Family Law Attorney Brian D. Smith"
                    href="https://profiles.superlawyers.com/ohio/perrysburg/lawyer/brian-d-smith/fcef9487-15e1-49d4-b983-eabf1152b182.html?utm_source=fcef9487-15e1-49d4-b983-eabf1152b182&utm_campaign=v1-rsbadge-blue&utm_content=profile"
                  >
                    Brian D. Smith
                  </a>
                  <div style={{ marginTop: "6px" }}>Rated by Super Lawyers</div>
                </div>
              </LawyerBadge>
            </Col>
          </SpecialGrid>
        </Wrapper>
      </Section>
    </Layout>
  )
}

export default BrianSmith

export const BrainSmithQuery = graphql`
  query BrianSmithQuery {
    file(relativePath: { eq: "Brian_Smith.vcf" }) {
      publicURL
    }
  }
`
